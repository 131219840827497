export default {
    init: function () {
        // JavaScript to be fired on all pages
        $(document).on('click', '.ginput_container_number', function (event) {
            const $input = $(this).find('input');
            if (event.target === $input[0]) {
                event.preventDefault();
            } else {
                if (event.offsetX < $(this).width() / 2) {
                    if ($(this).find('input').val() > 1) {
                        $(this).find('input').val(parseInt($(this).find('input').val()) - 1);
                    }
                } else {
                    $(this).find('input').val(parseInt($(this).find('input').val()) + 1);
                }
            }
        });

        function isIE() {
            var ua = navigator.userAgent;
            /* MSIE used to detect old browsers and Trident used to newer ones*/
            var is_ie = ua.indexOf('MSIE') > -1 || ua.indexOf('Trident/') > -1;

            return is_ie;
        }

        /* Create an alert to show if the browser is IE or not */
        if (isIE()) {
            var root = document.documentElement;
            root.className += ' ie11';
        }

        // // Jquery-match-height
        // $(function() {
        //     $('.banner__slide').matchHeight();
        // });

        // // Slick slider example
        // $('.slick__slider').slick({
        //     dots: true,
        //     infinite: true,
        //     speed: 300,
        //     slidesToShow: 1,
        //     slidesToScroll: 1,
        //     responsive: [
        //         {
        //             breakpoint: 1024,
        //             settings: {

        //             },
        //         },
        //     ],
        // });

        // $(document).ready(function() {
        //     $(".fancybox").fancybox();
        // });

        (function ($) {
            $('.align__center--impression').on('click', function () {
                $('.impression__imgs').toggleClass('impression__imgs--open');
            });

            $('.footer__btn__prijs').on('click', function () {
                $('html, body').stop().animate({
                    scrollTop: $('.front__form__col').offset().top - 100,
                }, 1000);
            });

            $(window).on('scroll', function () {
                if ($(this).scrollTop() > 200) {
                    $('.header-button-container > span').hide();
                } else {
                    $('.header-button-container > span').show();
                }
            });
        })(jQuery);
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
