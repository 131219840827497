$(document).ready(function() {

    // Slick slider banner

    const settings = {
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
        dots: false,
        fade: false,
        slidesToShow: 1,
        speed: 1700,
        pauseOnHover: false,
        centerMode: true,
        variableWidth: true,
        touchThreshold:100,
        responsive: [
            {
                breakpoint: 9999,
                settings: 'unslick',
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToScroll: 1,
                },
            },
        ],
    }

    $('.banner__slider').slick(settings);

    $(window).on('resize', function() {
        if( $(window).width() < 767 &&  !$('.banner__slider').hasClass('slick-initialized')) {
            $('.banner__slider').slick(settings);
        }
    });

    // Slick slider banner
    $('.banner__slider__sub__inner').slick({
        autoplay: true,
        autoplaySpeed: 4000,
        arrows: false,
        dots: false,
        fade: false,
        slidesToShow: 1,
        speed: 1700,
        pauseOnHover: false,
        variableWidth: false,
    });

});
