$(document).ready(function() {
    let menuButton = $('.js-toggle-menu');
    let circleEffect = $('.circle-effect');
    let menuOpen = $('.menu-open');
    let menuClose = $('.menu-close');
    let contactButtons = $('.contact-buttons');

    menuButton.click(function() {
        $('body').toggleClass('header-menu-open');
        circleEffect.toggleClass('circle-effect__active');
        menuOpen.toggleClass('hidden');
        menuClose.toggleClass('hidden');
        contactButtons.toggleClass('opacity-0 h-0 opacity-100 h-full');
    });

    let subMenuButtons = $('.js-toggle-submenu');
    let subMenu = $('.toggle-submenu');

    function isTouchDevice() {
        return (('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0) ||
            (navigator.msMaxTouchPoints > 0));
    }

    let isTouch = isTouchDevice();

    if (isTouch) {
        $(document).click(function(e) {
            if ($(e.target).hasClass('header')) {
                $('.header__menu').removeClass('submenu-open');
                $('.header__menu__item').removeClass('submenu-open');
                $('.header__menu').css('pointer-events', '');
                subMenu.each(function() {
                    $(this).next().removeClass('header-submenu__open');
                });
                $('.header__menu__item.group\\/level1').removeClass('opacity-50');
                $('.header__menu__item.group\\/level2').removeClass('opacity-50');
            }
        });

        subMenuButtons.click(function() {
            $('.header__menu').addClass('submenu-open');
            $('.header__menu').css('pointer-events', 'none');
        });

        $('.header__menu__item.group\\/level1').each(function() {
            $(this).closest('.header__menu__item').click(function() {
                $('.header__menu__item.group\\/level1').addClass('opacity-50');
                $(this).removeClass('opacity-50');
            });
        });

        $('.header__menu__item.group\\/level2').each(function() {
            $(this).closest('.header__menu__item').click(function() {
                $('.header__menu__item.group\\/level2').addClass('opacity-50');
                $(this).removeClass('opacity-50');
            });
        });

        subMenu.each(function() {
            $(this).closest('.header__menu__item').click(function() {
                $(this).addClass('open-submenu');
                $(this).next().addClass('header-submenu__open');
            });
        });
    } else {
        subMenuButtons.click(function() {
            $('.header__menu').addClass('submenu-open');
            $('.header__menu').css('pointer-events', 'none');
        });

        $('.header__menu__item.group\\/level1').each(function() {
            $(this).closest('.header__menu__item').hover(function() {
                $('.header__menu__item.group\\/level1').addClass('opacity-50');
                $(this).removeClass('opacity-50');
            }, function() {
                $('.header__menu__item.group\\/level1').removeClass('opacity-50');
                $('.header__menu').removeClass('submenu-open');
                setTimeout(function () {
                    $('.header__menu').css('pointer-events', '');
                }, 300);
            });
        });

        $('.header__menu__item.group\\/level2').each(function() {
            $(this).closest('.header__menu__item').hover(function() {
                $('.header__menu__item.group\\/level2').addClass('opacity-50');
                $(this).removeClass('opacity-50');
            }, function() {
                $('.header__menu__item.group\\/level2').removeClass('opacity-50');
            });
        });

        subMenu.each(function() {
            $(this).closest('.header__menu__item').hover(function() {
                $(this).toggleClass('open-submenu');
                $(this).next().toggleClass('header-submenu__open');
            });
        });
    }
});